export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ER App"])},
  "appTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange Rates Application"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convert currency values from EUR, USD, GBP and CZK currencies."])},
  "currencyConvertFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convert from:"])},
  "currencyConvertTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to:"])},
  "currencyConvertResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result:"])},
  "currencyInsertValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter amount to convert"])},
  "currencyTextFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "localeEnglish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "localeSlovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
  "apiStatusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "apiResponseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad request. Try again."])},
  "refreshStatusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "refreshResponseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh is available every 3 days. Please try again on this date:"])},
  "refreshRatesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh rates"])},
  "expansionTileNumberSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an amount by clicking here"])},
  "lastExchangeRateUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last exchange rates update"])}
}