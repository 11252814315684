export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ER Aplikácia"])},
  "appTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia pre výmenný kurz"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skonvertujte hodnoty mien z Eur, Dolárov, Libier a Českej koruny."])},
  "currencyConvertFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konvertovať z:"])},
  "currencyConvertTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na:"])},
  "currencyConvertResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledok:"])},
  "currencyInsertValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma pre skonvertovanie"])},
  "currencyTextFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "localeEnglish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglicky"])},
  "localeSlovak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovensky"])},
  "apiStatusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
  "apiResponseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlá požiadavka. Skús znova."])},
  "refreshStatusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
  "refreshResponseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovenie je dostupné každé 3 dni. Prosím skús to znova v tento dátum:"])},
  "refreshRatesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnoviť kurz"])},
  "expansionTileNumberSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadaj číslo kliknutím sem"])},
  "lastExchangeRateUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledná aktualizácia kurzov"])}
}